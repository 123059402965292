import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";

const login = () => import("@/views/Login.vue");

//---Start Super_admin
const companies = () => import("@/views/super_admin/companies");
const languages = () => import("@/views/super_admin/languages");
//---End Super admin

const employees = () => import("@/views/companies/employees");
const routes = [
  {
    path: "/companies",
    name: "companies",
    component: companies,
    meta: {
      title: "companies",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/languages",
    name: "languages",
    component: languages,
    meta: {
      title: "languages",
      requiresAuth: true,
      super_admin: true,
    },
  },
  //////////////------------- End Super_admin
  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
      super_admin: true,
    },
  },
  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (to.name != "notfound") {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next();
      } else {
        if (!store.state.kalko.is_company) {
          next("/companies");
        } else {
          next("/employees");
        }
      }
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next({
          name: "login",
        });
      } else {
        if (!store.state.kalko.is_company) {
          if (!to.meta.super_admin) {
            next("*");
          } else {
            next();
          }
        } else {
          if (!to.meta.super_admin) {
            next("*");
          } else {
            next();
          }
        }
      }
    }
  } else {
    next();
  }
});
