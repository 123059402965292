import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@res",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    drawer: true,
    mini: false,
    def_path: "https://api.kalko.cloud",
    //def_path: "http://127.0.0.1:8000",
    token: encryptStorage.getItem("token_kalko") || null,
    kalko: encryptStorage.getItem("kalko") || {
      is_company: false,
    },
    break_point: 0,

    langs: {
      done: false,
      data: [],
    },
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    items: (stat) => (items) => {
      return stat[items];
    },
    default_lang(state) {
      if (state.langs.done) {
        return state.langs.data.find((l) => l.is_default);
      }
      return {
        locale: "",
      };
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_kalko");
      encryptStorage.removeItem("kalko");
    },
    user(state, user) {
      state.kalko = user;
    },

    store_items(state, items) {
      state[items.items].data = items.data;
      if (items.data.length) {
        state[items.items].done = true;
      }
    },

    update_props(state, obj) {
      state[obj.items].data[obj.index][obj.prop] = obj.val;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        var config = {
          method: "post",
          url: "https://ba.kalko.com/api/v1/lead/login",
          url: "login",
          data: formData,
        };
        axios(config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    check_code(context, cred) {
      return new Promise((resolve, reject) => {
        var config = {
          method: "post",
           url: `https://api.kalko.cloud/api/v1/checkCode/${cred.code}`,
          //url: `http://127.0.0.1:8000/api/v1/checkCode/${cred.code}`,
          headers: {
            Accept: "application/json",
          },
        };
        axios(config)
          .then((res) => {
            const token = res.data.token;
            encryptStorage.setItem("token_kalko", token);
            encryptStorage.setItem("kalko", res.data);
            context.commit("login", token);
            context.commit("user", res.data);

            if (!res.data.is_company) {
              router.push("/companies");
            } else {
              router.push("/employees");
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            // Accept: "application/json",
            // "Content-Type": cred.config.type
            //   ? cred.config.type
            //   : "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
    get_langs(context, cred) {
      context
        .dispatch("public__request", {
          config: {
            url: !encryptStorage.getItem("kalko").is_company
              ? "superadmin/languages"
              : "company/languages",
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;

          if (!encryptStorage.getItem("kalko").is_company) {
            context.commit("store_items", {
              items: "langs",
              data: data,
            });
          } else {
            context.commit("store_items", {
              items: "langs",
              data: data.langs,
            });
            // context.commit("store_items", {
            //   items: "translations",
            //   data: data.translations,
            // });
          }
        });
    },
  },
  modules: {},
});
