<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <h1>Kalko</h1>

        <v-spacer></v-spacer>

        <div class="d-flex">
          <v-menu offset-y v-if="$store.state.kalko.is_store">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white black--text" dark v-bind="attrs" v-on="on">
                {{ selectedLang }}
                <v-icon small class="ms-2">language</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="selectLang(lang)"
                v-for="(lang, index) in $store.state.langs.data"
                :key="index"
              >
                <v-list-item-title>
                  {{ lang.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ms-3"
            v-if="$store.state.break_point <= 991"
            small
            icon
            @click="$store.state.drawer = !$store.state.drawer"
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {},
  computed: {
    selectedLang() {
      const selected = this.$store.state.langs.data.find(
        (e) => e.locale == this.$store.state.dash_lang
      );
      return selected ? selected.name : "";
    },
  },
  methods: {
    selectLang(lang) {
      document.cookie = `locale=${lang.locale}`;
      this.$store.state.dash_lang = lang.locale;
    },
  },
};
</script>
