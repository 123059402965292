export default {
  name: "StaticTrans",
  data() {
    return {};
  },
  computed: {
    trans: (app) => (page, name) => {

      if (app.$store.state.kalko.is_store) {
        return app.$store.getters.TransName({
          page: page,
          name: name,
        })  ;
      }
      return '';
    },
  },
};
