<template>
  <v-navigation-drawer
    class="menu rounded"
    floating
    v-model="$store.state.drawer"
    right
    :mini-variant.sync="$store.state.mini"
    :app="$store.state.break_point <= 991"
    :style="{
      height:
        $store.state.break_point <= 991 ? '100%!important' : 'auto!important',
    }"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
      </v-list-item-avatar>
      <v-list-item-title
        >{{ $store.state.kalko ? $store.state.kalko.name : "Super Admin" }}
      </v-list-item-title>

      <v-btn
        color="grey lighten-3"
        fab
        x-small
        depressed
        v-if="$store.state.break_point > 991"
        @click.stop="$store.state.mini = !$store.state.mini"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense v-for="(list, i) in links" :key="i">
      <v-list-item-title
        v-if="!$store.state.mini"
        class="grey--text lighten-3 caption px-4"
        >{{ list.title }}</v-list-item-title
      >
      <v-list-item
        active-class="primary white--text"
        v-for="(item, x) in list.list"
        :key="x"
        link
        :to="{ name: item.link }"
      >
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item active-class="primary white--text" @click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            התנתק
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="i != list.list.length - 1"></v-divider>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: true,

      mini: false,
    };
  },
  computed: {
    all_links() {
      return [
        {
          title: "",
          super: false,
          list: [
            {
              title: "עובדים",
              icon: "group",
              link: "settings",
            },
          ],
        },
        {
          title: "",
          super: true,
          list: [
            {
              title: "חברות",
              icon: "apartment",
              link: "companies",
            },
          ],
        },
      ];
    },
    links() {
      let links = [];
      this.all_links.forEach((e) => {
        if (e.super == !this.$store.state.kalko.is_company) {
          links.push(e);
        }
      });
      return links;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.menu {
  // min-width: 245px;
  height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
  @media (max-width: 991px) {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  max-height: calc(100vh - 200px) !important;
  box-shadow: 0px 3px 1px -5px rgb(0 0 0 / 12%), 0px 2px 4px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
</style>
